const state = {
  tournament: {},
  newContentAvailable: null,
  refreshingApp: false,
  updateDismissed: false,
  installPromptDismissed: false,
  loading: true,
  showDrawer: false,
  showNavIcon: false,
  currentTeamID: null,
  currentObJTeam: {},
  currentDivisionID: null,
  currentObjDivision: null,
  currentOrganization: null
}

const getters = {
  currentTeamID: (state) => {
    return state.currentTeamID
  },
  currentDivisionID: (state) => {
    return state.currentDivisionID
  },
  currentObjDivision: (state) => {
    return state.currentObjDivision
  },
  currentObJTeam: (state) => {
    return state.currentObJTeam
  },
  currentOrganization: (state) => {
    return state.currentOrganization
  }
}

const actions = {}

const mutations = {
  SET_LOADING (state, value) {
    state.loading = value
  },
  SET_NEW_CONTENT_AVAILABLE (state, value) {
    state.newContentAvailable = value
  },
  SET_REFRESHING_APP (state, value) {
    state.refreshingApp = value
  },
  DISMISS_UPDATE (state, value) {
    state.updateDismissed = value
  },
  SET_TOURNAMENT (state, value) {
    state.tournament = value
  },
  SET_SHOW_DRAWER (state) {
    state.showDrawer = !state.showDrawer
  },
  SET_SHOW_NAV_ICON (state, value) {
    state.showNavIcon = value
  },
  SET_CURRENT_TEAM (state, value) {
    state.currentTeamID = value
    localStorage.setItem('currentTeamID', value)
  },
  SET_CURRENT_OBJ_TEAM (state, value) {
    state.currentObJTeam = value
  },
  SET_CURRENT_DIVISION_ID (state, value) {
    state.currentDivisionID = value
  },
  SET_CURRENT_OBJ_DIVISION (state, value) {
    state.currentObjDivision = value
  },
  SET_CURRENT_ORGANIZATION (state, value) {
    state.currentOrganization = value
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
