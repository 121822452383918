import Vue from 'vue'
import Vuex from 'vuex'

import app from './app'
import auth from './auth'
import snackbar from './snackbar'
import globalModule from './modules/_index'
import bracketManager from './bracketManager'
import livepage from './livepage'
import cart from './cart'
import checkoutProcess from './checkoutProcess'
import registrationSteps from './registrationSteps'

import legacy from './legacy'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    cart,
    legacy,
    snackbar,
    bracketManager,
    livepage,
    checkoutProcess,
    registrationSteps,
    // API Stores
    ageCategory: globalModule('AgeCategory'),
    badge: globalModule('Badge'),
    badgeUser: globalModule('BadgeUser'),
    bracket: globalModule('bracket'),
    bracketlive: globalModule('BracketLive'),
    creditCard: globalModule('PaymentCreditCardMethod'),
    division: globalModule('Division'),
    event: globalModule('Event'),
    eventVenue: globalModule('EventVenue'),
    eventsOnCoach: globalModule('EventsOnCoachView'),
    file: globalModule('File'),
    files: globalModule('Files'),
    filesPublic: globalModule('FilesPublic'),
    guardian: globalModule('Guardian'),
    match: globalModule('match'),
    matchPlaceholder: globalModule('MatchPlaceholder'),
    organization: globalModule('Organization'),
    passType: globalModule('PassType'),
    paymentCheckout: globalModule('PaymentSessionCheckout'),
    paymentDiscount: globalModule('PaymentDiscount'),
    person: globalModule('Person'),
    placeholder: globalModule('placeholder'),
    player: globalModule('Player'),
    playerAffiliation: globalModule('PlayerAffiliation'),
    playerAward: globalModule('PlayerAward'),
    playerProfile: globalModule('PlayerProfile'),
    playerSport: globalModule('PlayerSport'),
    playerStatistic: globalModule('PlayerStatistic'),
    pool: globalModule('Pool'),
    publicEvents: globalModule('PublicEvents'),
    publicOrgs: globalModule('PublicOrgs'),
    publicTickets: globalModule('PublicTickets'),
    registerTeamOrg: globalModule('RegisterTeamOrg'),
    registration: globalModule('Registration'),
    registrationPublic: globalModule('RegistrationPublic'),
    registrationSurvey: globalModule('RegistrationSurvey'),
    registrationSurveyAnswer: globalModule('RegistrationSurveyAnswer'),
    registrationSurveyQuestion: globalModule('RegistrationSurveyQuestion'),
    roster: globalModule('Roster'),
    session: globalModule('PaymentSession'),
    sessionClear: globalModule('PaymentSessionClear'),
    sessionItem: globalModule('PaymentSessionItem'),
    shippingAddress: globalModule('ShippingAddress'),
    shippingConfig: globalModule('ShippingConfig'),
    sport: globalModule('Sport'),
    standing: globalModule('standing'),
    storeAttribute: globalModule('StoreAttribute'),
    storeCategory: globalModule('StoreCategory'),
    storeProduct: globalModule('StoreProduct'),
    subvenue: globalModule('subvenue'),
    team: globalModule('Team'),
    template: globalModule('Template'),
    ticketDates: globalModule('TicketsEventDate'),
    ticketGeneralFilters: globalModule('TicketGeneralFilters'),
    ticketPublicTemplate: globalModule('TicketPublicTemplate'),
    ticketTransfer: globalModule('TicketTransfer'),
    ticketTypes: globalModule('TicketTypes'),
    ticketVenues: globalModule('TicketVenues'),
    tickets: globalModule('tickets'),
    tournament: globalModule('tournament'),
    tournamentStatus: globalModule('TournamentStatus'),
    user: globalModule('User'),
    userClient: globalModule('UserClient'),
    validatePayment: globalModule('ValidatePayment'),
    venue: globalModule('Venue'),
    verifyEmail: globalModule('VerifyEmail'),
    verifyPhone: globalModule('VerifyPhone'),
    social: globalModule('Social'),
    socialPost: globalModule('SocialPost'),
    ticketDownloadPdf: globalModule('TicketDownloadPdf'),
    ticketHistoryToken: globalModule('TicketHistoryToken'),
    publicOrganizationSite: globalModule('PublicOrganizationSite')
  }
})
