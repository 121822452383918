import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import AuthLayout from '@/layouts/AuthLayout'

Vue.use(Router)

function generateRoutes (environment) {
  const commonRoutes = [
    {
      path: '/organization/:organizationID',
      name: 'organization',
      component: () => import('@/views/Organization.vue')
    },
    {
      path: '/team/:teamID',
      name: 'team',
      component: () => import('@/views/Team.vue')
    },
    {
      path: '/player/:profileID',
      name: 'PlayerProfile',
      component: () => import('@/views/Player.vue')
    },
    {
      path: '/tournament',
      name: 'Tournament',
      props: true,
      component: () => import('@/views/Tournament.vue'),
      children: [
        {
          path: '/tournament/:tournamentID/divisions',
          name: 'Division Details',
          component: () => import('@/views/Division.vue')
        }
      ]
    },
    {
      path: '/event/:slug',
      name: 'Event landing',
      component: () => import('@/views/event/LandingOLD.vue')
    },
    {
      path: '/live/:slug',
      name: 'Event live',
      component: () => import('@/views/event/EventSinglePageOLD.vue'),
      beforeEnter: async (to, from, next) => {
        const slug = to.params.slug
        try {
          const { organization } = await store.dispatch('publicEvents/fetchByIdNoError', {
            id: slug,
            params: {
              include: [{ organization: ['slug'] }]
            }
          })
          const flagView = organization?.parentOrganization
          store.commit('app/SET_CURRENT_ORGANIZATION', organization)
          if (flagView) {
            if (flagView === 'new') {
              to.matched[0].components.default = () => import('@/views/event/EventSinglePage.vue')
            } else {
              to.matched[0].components.default = () => import('@/views/event/EventSinglePageOLD.vue')
            }
          } else {
            to.matched[0].components.default = () => import('@/views/event/EventSinglePageOLD.vue')
          }
        } catch (error) {
          console.error('Error al verificar el evento:', error)
          to.matched[0].components.default = () => import('@/views/event/EventSinglePageOLD.vue')
        }
        next()
      }
    },
    {
      path: '/live/:slug/store',
      name: 'Event store',
      component: () => import('@/views/event/StoreViewOLD.vue')
    },
    {
      path: '/live/:slug/team/:teamID',
      name: 'Event individual reservation',
      component: () => import('@/views/hotels/EventHotelsOverview.vue')
    },
    {
      path: '/live/:slug/team/:teamID/rooms',
      name: 'Event individual reservation rooms',
      component: () => import('@/views/hotels/EventHotelsRooms.vue')
    },
    {
      path: '/confirmation-booking/:orderID',
      name: 'Confirmation Booking',
      component: () => import('./views/ShoppingCart/ConfirmationBooking')
    },
    {
      path: '/confirmation-team-booking/:orderID',
      name: 'Confirmation Team Booking',
      component: () => import('./views/ShoppingCart/ConfirmationBookingTeam')
    },
    {
      path: '/ticketStore',
      name: 'Tickets',
      component: () => import('./components/organizationStore/EventCard.vue')
    },
    {
      path: '/tickets/passType/event/:eventID/passType/:passTypeID',
      name: 'PassType',
      component: () =>
        import('./components/organizationStore/TypePassContainer.vue')
    },
    {
      path: '*',
      name: '404',
      component: () => import('./views/404.vue')
    },
    {
      path: '/error',
      name: 'Error',
      component: () => import('./views/Error.vue')
    },
    {
      path: '/download/tickets/:token',
      name: 'Download Tickets',
      component: () => import('./views/DownloadTickets.vue')
    }
  ]

  const devRoutes = [
    {
      path: '/',
      redirect: '/org',
      component: AuthLayout,
      children: [
        {
          path: 'org/',
          name: 'AllEvents',
          component: () => import('@/views/AllEvents')
        },
        {
          path: 'org/:slugOrg',
          name: 'OrganizationSite',
          component: () => import('@/views/websites/OrganizationSite')
        },
        {
          path: 'org/:slugOrg/:module',
          name: 'OrganizationSite Module',
          component: () => import('@/views/websites/OrganizationSiteSingleItem')
        },
        {
          path: 'org/:slugOrg',
          name: 'SingleOrg',
          component: () => import('@/views/SingleOrg')
        },
        {
          path: 'org/:slugOrg/event/:slugEvent',
          name: 'EventMultipleTournaments',
          component: () => import('@/views/EventMultipleTournaments')
        },
        {
          path: 'org/:slugOrg/event/:slugEvent/tournament/:tournamentID/divisions',
          name: 'Divisions',
          component: () => import('@/views/EventSingleTournament')
        },
        {
          path: 'org/:slugOrg/event/:slugEvent/tournament/:tournamentID/ticketStore',
          name: 'TicketStore',
          component: () => import('@/views/TicketStore')
        }
      ]
    },
    {
      path: '',
      component: AuthLayout,
      children: [
        {
          path: 'login/',
          name: 'Login',
          component: () => import('@/views/Login')
        },
        {
          path: 'register/',
          name: 'Register',
          component: () => import('@/views/Register')
        },
        {
          path: 'recover/',
          name: 'Recover',
          component: () => import('@/views/Recover')
        },
        {
          path: 'verify/',
          name: 'Verify',
          component: () => import('@/views/Verify')
        },
        {
          path: 'terms/',
          name: 'Terms',
          component: () => import('@/views/Terms')
        },
        {
          path: '/checkout-cart/:registrationId?/:deposit?',
          name: 'CheckoutCart',
          component: () => import('./views/ShoppingCart/CheckoutCart')
        },
        {
          path: '/confirmation-payment',
          name: 'ConfirmationPayment',
          component: () => import('./views/ShoppingCart/ConfirmationPayment')
        },
        {
          path: '//ValidatePayment',
          name: 'ValidatePayment',
          component: () => import('./views/ShoppingCart/ValidatePayment')
        }
      ]
    }
  ]

  const prodRoutes = [
    {
      path: '/',
      redirect: '/login',
      component: AuthLayout,
      children: [
        {
          path: 'login/',
          name: 'Login',
          component: () => import('@/views/Login')
        },
        {
          path: 'register/',
          name: 'Register',
          component: () => import('@/views/Register')
        },
        {
          path: 'recover/',
          name: 'Recover',
          component: () => import('@/views/Recover')
        },
        {
          path: 'verify/',
          name: 'Verify',
          component: () => import('@/views/Verify')
        },
        {
          path: 'terms/',
          name: 'Terms',
          component: () => import('@/views/Terms')
        },
        {
          path: '/checkout-cart/:registrationId?/:deposit?',
          name: 'CheckoutCart',
          component: () => import('./views/ShoppingCart/CheckoutCart')
        },
        {
          path: '/confirmation-payment',
          name: 'ConfirmationPayment',
          component: () => import('./views/ShoppingCart/ConfirmationPayment')
        },
        {
          path: '//ValidatePayment',
          name: 'ValidatePayment',
          component: () => import('./views/ShoppingCart/ValidatePayment')
        }
      ]
    },
    {
      path: '/',
      redirect: '/org',
      component: AuthLayout,
      children: [
        {
          path: 'org/:slugOrg',
          name: 'OrganizationSite',
          component: () => import('@/views/websites/OrganizationSite')
        },
        {
          path: 'org/:slugOrg/:module',
          name: 'OrganizationSite Module',
          component: () => import('@/views/websites/OrganizationSiteSingleItem')
        }
      ]
    }
  ]
  return environment === 'production' ? [...prodRoutes, ...commonRoutes] : [...devRoutes, ...commonRoutes]
}

const environment = process.env.NODE_ENV !== 'production' ? 'development' : 'production'

export default new Router({
  mode: 'history',
  base: '/',
  routes: generateRoutes(environment)
})
