export default async (exportName, fetchData) => {
  try {
    const data = await fetchData
    const pom = document.createElement('a')
    const blob = new Blob([data])

    const url = URL.createObjectURL(blob)

    pom.href = url
    pom.setAttribute('download', `${exportName}.csv`)
    pom.click()

    URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error downloading the file:', error)
  }
}
